import { useNavigate } from "react-router-dom";
import { useGameContext } from "../../contexts/GameContext/GameContext";
import JustButton from "../JustButton/JustButton";
import "../../App.css";
import "./style.css";

const getPathDpr = (dpr: number) => `/imgs/dpr_${dpr}/endgame/try_again.webp`;

export default function TryAgain() {
    const gameContext = useGameContext();
    if (!gameContext) {
        throw new Error("Game context is not initialized properly");
    }

    const { resetGameState } = gameContext;
    const navigate = useNavigate();

    function restartGame() {
        resetGameState();
        navigate('/game');
    }

	return (
		<main className="page__main try_again__bg-img">
			<div className="page__content no__gap">
				<h2 className="page__title">Попробуйте еще раз</h2>
				<p className="page__descr">
                    К сожалению, вы не успели найти все карточки! Не расстраивайтесь, у вас есть ещё попытка!
				</p>
				<div className="page__img ticket__img">
					<picture>
						<source srcSet={getPathDpr(1)} media="(max-resolution: 1dppx)" />
						<source srcSet={getPathDpr(2)} media="(min-resolution: 2dppx)" />
						<source srcSet={getPathDpr(3)} media="(min-resolution: 3dppx)" />
						<img
							className="page__img ticket__img"
							src={getPathDpr(2)}
							alt=""
						/>
					</picture>
				</div>
				<JustButton onClick={restartGame} variant="white">Играть ещё раз</JustButton>
			</div>
		</main>
	);
}
