import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import "./fact.css";
import { FeedFact, StaringFact, HunterFact, SmileyFact, SoupFact, PiecesFact } from "../../data/facts";
import { LinkButton } from "../../components/LinkButton";
import { useGameContext } from "../../contexts/GameContext/GameContext";
import { Card } from "../../components/GameCard";
import { EndGameType } from "../Endgame";
import { MouseEventHandler, useEffect } from "react";
import { useTokenContext } from "../../contexts/tokenContext/tokenContext";
import { redirectToCantProceed } from "../StartPage";

export type ItemRouteSlug = 'feed' | 'staring' | 'pieces' | 'soup' | 'smiley' | 'hunter';

export default function Fact() {
    const tokenContext = useTokenContext();
    if (!tokenContext) {
        throw new Error("Token context is not initialized properly");
    }
    
    const navigate = useNavigate();

    useEffect(() => {
        if (!tokenContext.token.current) {
            redirectToCantProceed(navigate);
        }
    }, []);

    const gameContext = useGameContext();
    if (!gameContext) {
        throw new Error("Game context is not initialized properly");
    }

    const { cardsState, setTimerState, timerState, setEndGameState } = gameContext;

    const { slug } = useParams() as { slug: ItemRouteSlug };
    let FactComponent;

    switch (slug) {
        case 'feed': 
            FactComponent = <FeedFact />
            break;
        case 'hunter':
            FactComponent = <HunterFact />
            break;
        case 'pieces':
            FactComponent = <PiecesFact />
            break;
        case 'smiley':
            FactComponent = <SmileyFact />
            break;
        case 'soup':
            FactComponent = <SoupFact />
            break;
        case 'staring':
            FactComponent = <StaringFact />
            break;
    }

    const handleButtonClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
        const isAllCardsFlipped = checkIsAllCardsFlipped(cardsState);
        // redirect to win screen
        if (isAllCardsFlipped) {
            e.preventDefault();
            setEndGameState(true);
            navigateToWinScreen(navigate);
        }
        else {
            setTimerState({
                ...timerState,
                isRunning: true,
            });
        }
    }

    return (
        <main className="fact__main">
            <h2 className="page__title">Интересный<br/>факт</h2>
            <>
                {FactComponent}
                <div className="link__wrapper">
                    <LinkButton 
                        to="/game" 
                        text="Понятно" 
                        onClick={handleButtonClick}
                    />
                </div>
            </>
        </main>
    );
}

function checkIsAllCardsFlipped(cards: readonly Card[]): boolean {
    for (const card of cards) {
      if (!card.isFlipped) return false;
    }
    return true;
}

function navigateToWinScreen(navigate: NavigateFunction) {
    const screenType: EndGameType = 'win';
    navigate("/endgame/" + screenType);
}