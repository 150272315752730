import { useEffect, useRef, useState } from "react";
import { TokenContext } from "./tokenContext";
import Thumbnail from "../../routes/Thumbnail";

export default function TokenContextWrapper({ children }: {
    children: React.ReactNode;
}) {
    const tokenState = useRef("");
    const [isInvalidScreenRatio, setIsInvalidScreenRatio] = useState(false);

    // check for screen resolution
    useEffect(() => {
      if (document.documentElement.clientWidth >= 768) {
        setIsInvalidScreenRatio(true);
      }
    }, []);

    return (
      <TokenContext.Provider value={{
        token: tokenState,
      }}>
        {isInvalidScreenRatio && <Thumbnail />}
        {!isInvalidScreenRatio && children}
      </TokenContext.Provider>
    );
}