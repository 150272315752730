import { useGameContext } from '../../contexts/GameContext/GameContext'
import { GameField } from '../../components/GameField'
import { Timer } from '../../components/Timer'
import './Game.css'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTokenContext } from '../../contexts/tokenContext/tokenContext'
import { redirectToCantProceed } from '../StartPage'

export const Game = () => {
  const tokenContext = useTokenContext();
  if (!tokenContext) {
      throw new Error("Token context is not initialized properly");
  }
  
  const navigate = useNavigate();

  useEffect(() => {
      if (!tokenContext.token.current) {
          redirectToCantProceed(navigate);
      }
  }, []);

  const gameContext = useGameContext();
  if (!gameContext) {
    throw new Error("Game context is not initialized properly");
  }

  const { timerState, setTimerState, setEndGameState } = gameContext;

  return (
    <div className='game__wrapper'>
      <Timer 
        timerState={timerState}
        setTimerState={setTimerState}
        setEndGameState={setEndGameState}
      />
      <GameField 
        timerState={timerState} 
        setTimerState={setTimerState}
      />
    </div>
  )
}