import './Thumbnail.css'

const getPathDpr = (name: string, dpr: number) => `/imgs/dpr_${dpr}/${name}.webp`;

export default function Thumbnail() {

  return (
    <div className='thumbnail__wrapper'>
      <div className='thumbnail__title__block'>
        <h1 className='thumbnail__title thumbnail__title--desk'>
          игра доступна<br/>
          только в мобильной версии
        </h1>
        <h1 className='thumbnail__title thumbnail__title--mob'>
          увы, вам нужно<br/>повторно<br/>запустить игру
        </h1>

        <picture className='felix'>
          <source srcSet={getPathDpr('felix-thumbnail', 1)} media="(max-resolution: 1dppx)">
          </source>
          <source srcSet={getPathDpr('felix-thumbnail', 3)} media="(min-resolution: 3dppx)">
          </source>
          <img 
            alt='Феликс'
            className='felix'
            src={getPathDpr('felix-thumbnail', 2)}
          />
        </picture>

      </div>
    </div>
  )
}