import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LinkButton } from '../../components/LinkButton';
import { Rule } from '../../components/Rule';
import { useTokenContext } from '../../contexts/tokenContext/tokenContext';
import { rulesData } from '../../data/rules'
import { redirectToCantProceed } from '../StartPage';
import './Rules.css'

export const Rules = () => {
  const tokenContext = useTokenContext();
  if (!tokenContext) {
      throw new Error("Token context is not initialized properly");
  }
  
  const navigate = useNavigate();

  useEffect(() => {
      if (!tokenContext.token.current) {
          redirectToCantProceed(navigate);
      }
  }, []);

  return (
    <div className='rules__wrapper'>
      <div className='rules__block'>
        <h1 className='title'>правила</h1>
        <ul className='rules__list'>
          {rulesData.map((rule, i) => {
            return <Rule key={i} text={rule.text} />
          })}
        </ul>
      </div>
      
      <LinkButton to='/game' text='начать' />
    </div>
  );
}