import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Rules } from './routes/Rules';
import { Game } from './routes/Game';
import './index.css';
import App from './App';
import Fact from './routes/Fact';
import Endgame from './routes/Endgame';
import Thumbnail from './routes/Thumbnail';
import GameContextWrapper from './contexts/GameContext/GameContextWrapper';
import TokenContextWrapper from './contexts/tokenContext/TokenContextWrapper';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <TokenContextWrapper>
        <GameContextWrapper>
          <Routes>
              <Route path="/" element={<App />} />
              <Route path="/rules" element={<Rules />} />
              <Route path="/game" element={<Game />} />
              <Route path="/fact/:slug" element={<Fact />} />
              <Route path="/endgame/:res" element={<Endgame />} />
              <Route path="/cant_proceed" element={<Thumbnail />} />
          </Routes>
        </GameContextWrapper>
      </TokenContextWrapper>
    </BrowserRouter>
  </React.StrictMode>
);