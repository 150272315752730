import './StartPage.css';
import { requestAuthUser } from '../../api/requests';
import { useEffect, useState } from 'react';
import { useTokenContext } from '../../contexts/tokenContext/tokenContext';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { getSearchParam } from '../../utils/getSearchParam';
import JustButton from '../../components/JustButton/JustButton';

const getPathDpr = (name: string, dpr: number) => `/imgs/dpr_${dpr}/${name}.webp ${dpr}x`;

export const StartPage = () => {
  const tokenContext = useTokenContext();
  if (!tokenContext) {
    throw new Error("token context isn't initialized properly");
  }

  const [canPlay, setCanPlay] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // if user come from already_played page
    const fromAlreadyParam = getSearchParam('from_already');
    if (fromAlreadyParam) {
      setCanPlay(true);
      return;
    }

    const tokenParam = getSearchParam('token');

    if (tokenParam) {
      tokenContext.token.current = tokenParam;

      // prod version
      requestAuthUser(tokenParam)
      .then(res => {
        if ('message' in res) {
          redirectToCantProceed(navigate);
        }
        else if ('gameStatus' in res) {
          if (res.gameStatus === 'passed') {
            redirectToAlreadyPlayed(navigate);
          }
          else {
            setCanPlay(true);
          }
        }
      });
    }
    // no token - can't proceed
    else {
      redirectToCantProceed(navigate);
    }

  }, []);

  return (
    <div className='main__wrapper'>
      <div className='magnit__logo'>
        <picture>
          <source srcSet={getPathDpr('magnit-logo', 1)} media="(max-resolution: 1dppx)" />
          <source srcSet={getPathDpr('magnit-logo', 2)} media="(min-resolution: 2dppx)" />
          <source srcSet={getPathDpr('magnit-logo', 3)} media="(min-resolution: 3dppx)" />
          <img 
            alt='Логотип Магнита'
            className='magnit__logo'
            src={getPathDpr('magnit-logo', 2)}
          />
        </picture>
      </div>

      <div className='title__block'>
        <h1 className='startpage__title'>Здорово праздновать с&nbsp;котом!</h1>
        <div className='felix'>
          <picture>
            <source srcSet={getPathDpr('felix-thumbnail', 1)} media="(max-resolution: 1dppx)" />
            <source srcSet={getPathDpr('felix-thumbnail', 2)} media="(min-resolution: 2dppx)" />
            <source srcSet={getPathDpr('felix-thumbnail', 3)} media="(min-resolution: 3dppx)" />
            <img 
              alt='Феликс'
              className='felix'
              src={getPathDpr('felix-thumbnail', 2)}
            />
          </picture>
        </div>
      </div>

      <JustButton 
        disabled={!canPlay}
        variant='white' 
        onClick={() => navigate("/rules")}
      >
        Играть
      </JustButton>
    </div>
  );
}

function redirectToAlreadyPlayed(navigate: NavigateFunction) {
  navigate("/endgame/played_already");
}

export function redirectToCantProceed(navigate: NavigateFunction) {
  navigate("/cant_proceed");
}